import React, { Fragment, useState } from "react";
import { FormBoxInput } from "../components/form/Form";
import { Button } from "../components/form/Form.styles";
import "./pages.styles.scss";

const AOPDriver = () => {
  const [trailerNo, setTrailerNo] = useState("");
  const [pickedUpTime, setPickedUpTime] = useState("");
  const [droppedOffTime, setDroppedOffTime] = useState("");
  const [pickedUpStation, setPickedUpStation] = useState("");
  const [droppedOffStation, setDroppedOffStation] = useState("");
  const [showPickedUpForm, setPickedUpForm] = useState(false);
  const [showDroppedOffForm, setShowDroppedOffForm] = useState(false);
  return (
    <div className="page-container">
      <br />
      <Button
        onClick={() => {
          setPickedUpForm(true);
          setShowDroppedOffForm(false);
        }}
      >
        Pick Up
      </Button>
      <br />
      <Button
        onClick={() => {
          setPickedUpForm(false);
          setShowDroppedOffForm(true);
        }}
      >
        Drop Off
      </Button>
      <div>
        <form className="contact-form" sub>
          {(showPickedUpForm || showDroppedOffForm) && (
            <FormBoxInput
              label="Trailer Number"
              placeholder="Trailer Number"
              value={trailerNo}
              type="text"
              onChange={(e) => {
                setTrailerNo(e.target.value.trim());
              }}
              error={null}
            />
          )}
          {showPickedUpForm && (
            <Fragment>
              <FormBoxInput
                label="Picked Up Time"
                placeholder="Picked Up Time"
                value={pickedUpTime}
                type="text"
                onChange={(e) => {
                  setPickedUpTime(e.target.value.trim());
                }}
                error={null}
              />
              <FormBoxInput
                label="Picked Up Station"
                placeholder="Picked Up Station"
                value={pickedUpStation}
                type="text"
                onChange={(e) => {
                  setPickedUpStation(e.target.value.trim());
                }}
                error={null}
              />
            </Fragment>
          )}
          {showDroppedOffForm && (
            <Fragment>
              <FormBoxInput
                label="Dropped Off Time"
                placeholder="Dropped Off Time"
                value={droppedOffTime}
                type="text"
                onChange={(e) => {
                  setDroppedOffTime(e.target.value.trim());
                }}
                error={null}
              />
              <FormBoxInput
                label="Dropped Off Station"
                placeholder="Dropped Off Station"
                value={droppedOffStation}
                type="text"
                onChange={(e) => {
                  setDroppedOffStation(e.target.value.trim());
                }}
                error={null}
              />
            </Fragment>
          )}
          {(showPickedUpForm || showDroppedOffForm) && <Button>Submit</Button>}
        </form>
      </div>
    </div>
  );
};

export default AOPDriver;
