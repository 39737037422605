import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingCircle from "../components/loading-circle/LoadingCircle";
import PHByLocationTable from "../components/ph-by-location-table/PHByLocationTable";

let locations = [
  "line-1",
  "line-2",
  "line-3",
  "line-4",
  "line-5",
  "line-6",
  "line-7",
  "unload-1",
  "unload-2",
  "slide",
  "unassigned",
];

const stationIds = ["3081", "0081"];
const FDX = () => {
  document.title = "FDX";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startTimeLoading, setStartTimeLoading] = useState(false);
  const [startTimes, setStartTimes] = useState([]);
  const [stationId, setStationId] = useState(3081);

  useEffect(() => {
    Axios.get("ph-and-location")
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (stationId) {
      setStartTimeLoading(true);
      Axios.get("start-times?sort=order&stationId=" + stationId)
        .then((res) => {
          setStartTimes(res.data.data);
          setStartTimeLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [stationId]);

  return (
    <div className="page-container">
      <div style={{ backgroundColor: "#FF6200", color: "#fff", padding: 20 }}>
        <h3 align="center">
          To get the route no. for 199/999 addresses, please check out{" "}
          <Link to="/unlocated-addresses">this link</Link>
        </h3>
      </div>
      <div style={{ width: "fit-content", padding: 20, margin: "0 auto" }}>
        <iframe
          title="fdx smaple video"
          src="https://fdx.s3.ap-southeast-1.amazonaws.com/FDX_New_Version.mp4"
          width="640"
          height="480"
        ></iframe>
      </div>
      {startTimeLoading ? (
        <div style={{ paddingTop: 100 }}>
          <LoadingCircle size={75} />
        </div>
      ) : (
        <div style={{ width: 300, margin: "20px auto" }}>
          <div
            style={{
              backgroundColor: "#4D148C",
              color: "#fff",
              padding: 10,
            }}
          >
            <select
              style={{
                width: "100%",
                backgroundColor: "#4D148C",
                border: "none",
                color: "#fff",
              }}
              onChange={(e) => setStationId(stationIds[e.target.selectedIndex])}
            >
              {stationIds.map((sId) => (
                <option
                  style={{ textAlign: "center", width: "100%" }}
                  value={sId}
                  selected={sId === stationId}
                >
                  {sId}
                </option>
              ))}
            </select>
          </div>
          {startTimes.map((startTime, i) => (
            <div
              style={{
                display: "flex",
                border: "1px solid #555",
                borderBottom:
                  i === startTimes.length - 1 ? "1px solid #555" : "none",
              }}
            >
              <p
                style={{
                  width: 100,
                  lineHeight: 1,
                  margin: "10px 0",
                  padding: "0 20px",
                }}
              >
                {startTime.name}
              </p>
              <p
                style={{
                  width: 100,
                  lineHeight: 1,
                  margin: "10px 0",
                  padding: "0 20px",
                }}
              >
                {startTime.date}
              </p>
              <p
                style={{
                  lineHeight: 1,
                  margin: "10px 0",
                  padding: "0 20px",
                }}
              >
                {startTime.time}
              </p>
            </div>
          ))}
        </div>
      )}
      {loading ? (
        <div style={{ paddingTop: 100 }}>
          <LoadingCircle size={75} />
        </div>
      ) : (
        <div>
          <div style={{ display: "flex", width: 700, margin: "0 auto" }}>
            {locations.slice(0, 2).map((location) => (
              <PHByLocationTable
                location={location}
                phByLocations={data.filter((ph) => ph.location === location)}
              />
            ))}
          </div>
          <div style={{ display: "flex", width: 700, margin: "0 auto" }}>
            {locations.slice(2, 4).map((location) => (
              <PHByLocationTable
                location={location}
                phByLocations={data.filter((ph) => ph.location === location)}
              />
            ))}
          </div>
          <div style={{ display: "flex", width: 700, margin: "0 auto" }}>
            {locations.slice(4, 6).map((location) => (
              <PHByLocationTable
                location={location}
                phByLocations={data.filter((ph) => ph.location === location)}
              />
            ))}
          </div>
          <div style={{ display: "flex", width: 700, margin: "0 auto" }}>
            {locations.slice(6, 8).map((location) => (
              <PHByLocationTable
                location={location}
                phByLocations={data.filter((ph) => ph.location === location)}
              />
            ))}
          </div>
          <div style={{ display: "flex", width: 700, margin: "0 auto" }}>
            {locations.slice(8, 10).map((location) => (
              <PHByLocationTable
                location={location}
                phByLocations={data.filter((ph) => ph.location === location)}
              />
            ))}
          </div>
          <div style={{ display: "flex", width: 700, margin: "0 auto" }}>
            {locations.slice(10, 12).map((location) => (
              <PHByLocationTable
                location={location}
                phByLocations={data.filter((ph) => ph.location === location)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FDX;
