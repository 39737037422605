import React, { useEffect } from "react";
import ProjectsContainer from "../components/projects-container/ProjectsContainer";

const Projects = () => {
  document.title = "Projects";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-container">
      <ProjectsContainer />
    </div>
  );
};

export default Projects;
