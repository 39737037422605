import React, { useState, useEffect, createRef } from "react";
import axios from "axios";

import {
  FormBoxInput,
  SubmitButton,
  FormTextArea,
  FormErrorMsg,
  FormSuccessMsg,
  FormSelect,
} from "../form/Form";
import "./ContactForm.styles.scss";
import { FormInputContainer } from "../form/Form.styles";
import AnimationContainer from "../translate-animation-container/AnimationContainer";
import LoadingCircle from "../loading-circle/LoadingCircle";
import SuccessMessageContainer from "../success-message-container/SuccessMessageContainer";
import { setSuccessMessage } from "../../redux/actions/successMessageActions";
import { connect } from "react-redux";

const options = [
  "New Website",
  "New Mobile App",
  "Update Current Website",
  "Others",
];

const ContactForm = ({ setSuccessMessage }) => {
  const [name, setName] = useState("");
  const [reason, setReason] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState({});
  const [isFilled, setIsFilled] = useState(false);
  const [selectStyle, setSelectStyle] = useState({ color: "#777" });
  const [loading, setLoading] = useState(false);
  const formSelectRef = createRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const newMail = { name, email, description, reason };
    setLoading(true);

    axios
      .post(`/customer-mails`, newMail)
      .then((res) => {
        setSuccessMessage("Sucessfully submitted.");
        setLoading(false);
        document.querySelector(".form-select").selectedIndex = "0";
        setName("");
        setEmail("");
        setDescription("");
        setReason("");
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isFilled) setSelectStyle({});
    return () => setSelectStyle({ color: "#777" });
  }, [isFilled]);

  const animationStyle = {
    transform: "translate(0)",
    opacity: 1,
  };

  const beforeAnimationStyle = {
    transform: "translate(-100px)",
    opacity: 0,
    transition: "transform 1s ease, opacity 1s ease",
  };

  const animationDelayDurationArray = [100, 200, 300, 400, 500];

  return (
    <form className="contact-form" sub>
      <h1 className="contact-form-title" style={{ margin: 20 }}>
        Contact
      </h1>
      <AnimationContainer
        animationStyle={animationStyle}
        beforeAnimationStyle={beforeAnimationStyle}
        animationDelay={animationDelayDurationArray[0]}
      >
        <FormBoxInput
          label="Name"
          placeholder="Enter your name"
          value={name}
          type="text"
          onChange={(e) => {
            setError({});
            setName(e.target.value.trim());
          }}
          error={error.name && error.name}
        />
      </AnimationContainer>
      <AnimationContainer
        animationStyle={animationStyle}
        beforeAnimationStyle={beforeAnimationStyle}
        animationDelay={animationDelayDurationArray[1]}
      >
        <FormSelect
          error={error.reason}
          label="Reason"
          selectedOption={reason}
          optionTitle="Please select a reason"
          style={selectStyle}
          className="select"
          name="reason"
          id="reason"
          onChange={(e) => {
            setError({});
            setReason(e.target.value.trim());
            setIsFilled(true);
          }}
          options={options}
        />
      </AnimationContainer>
      <AnimationContainer
        animationStyle={animationStyle}
        beforeAnimationStyle={beforeAnimationStyle}
        animationDelay={animationDelayDurationArray[2]}
      >
        <FormBoxInput
          label="Email"
          placeholder="Enter your email"
          value={email}
          type="text"
          onChange={(e) => {
            setEmail(e.target.value.trim());
            setError({});
          }}
          error={error.email && error.email}
        />
      </AnimationContainer>
      <AnimationContainer
        animationStyle={animationStyle}
        beforeAnimationStyle={beforeAnimationStyle}
        animationDelay={animationDelayDurationArray[3]}
      >
        <FormTextArea
          width="100%"
          label="Description"
          onChange={(e) => setDescription(e.target.value)}
          name="description"
          type="text"
          value={description}
          error={error.description && error.description}
          placeholder={
            description.length > 0 ? "" : "Please describe your need"
          }
          required
        />
      </AnimationContainer>

      <AnimationContainer
        animationStyle={animationStyle}
        beforeAnimationStyle={beforeAnimationStyle}
        animationDelay={animationDelayDurationArray[4]}
      >
        <SubmitButton disabled={loading} onClick={handleSubmit}>
          {!loading ? "Send" : <LoadingCircle size={20} />}
        </SubmitButton>
      </AnimationContainer>
    </form>
  );
};

export default connect(null, { setSuccessMessage })(ContactForm);
