import React from "react";
import "./Filter.styles.scss";
import { color } from "../../utils/theme";

const filterOptions = [
  "New Website",
  "New Mobile App",
  "Update Website",
  "Update Mobile App",
  "Others",
];

const Filter = ({ changeFilter, filter }) => {
  const getFilteredMails = (option) => {
    filter !== option ? changeFilter(option) : changeFilter("");
  };

  return (
    <div className="filter-container">
      <h1>Filter</h1>
      <div className="filter-selection-container">
        {filterOptions.map((option) => (
          <div
            className="filter"
            style={filter === option ? { backgroundColor: color.darkBlue } : {}}
            onClick={() => getFilteredMails(option)}
          >
            <p className="filter-text">{option}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Filter;
