export default [
  "https://images.unsplash.com/photo-1534161308652-fdfcf10f62c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2174&q=80",
  "https://images.unsplash.com/photo-1449034446853-66c86144b0ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80",
  "https://images.unsplash.com/photo-1470341223622-1019832be824?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2288&q=80",
  "https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80",
  "https://images.unsplash.com/photo-1534161308652-fdfcf10f62c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2174&q=80",
  "https://images.unsplash.com/photo-1449034446853-66c86144b0ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80",
];

// export default {
//   landscapeUrls: [
//     "https://images.unsplash.com/photo-1534161308652-fdfcf10f62c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2174&q=80",
//     "https://images.unsplash.com/photo-1449034446853-66c86144b0ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80",
//     "https://images.unsplash.com/photo-1470341223622-1019832be824?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2288&q=80",
//     "https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80",
//     "https://images.unsplash.com/photo-1534161308652-fdfcf10f62c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2174&q=80",
//     "https://images.unsplash.com/photo-1449034446853-66c86144b0ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80",
//   ],
//   portraitUrls: [
//     "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fbackground-images%2Fdave-hoefler-px-tX8dnE-A-unsplash_resize.jpg?alt=media&token=a384d6ca-bfe3-419c-80fe-9afa8e9729e8",
//     "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fbackground-images%2Fdave-hoefler-rw2sFOdBszI-unsplash_resize.jpg?alt=media&token=e4b23022-8c22-4b44-a8d1-161ddfada7b2",
//     "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fbackground-images%2Fivan-bandura-1Hp_6AnkWkc-unsplash_resize.jpg?alt=media&token=abd6557e-4269-4c3f-8b52-4edeb9038ade",
//     "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fbackground-images%2Fmalte-schmidt-enGr5YbjQKQ-unsplash_resize.jpg?alt=media&token=28525fb3-36eb-4512-85fc-8f1dd4087c11",
//   ],
// };
