import React from "react";
import Emails from "../components/emails/Emails";
import SearchForm from "../components/search-form/SearchForm";

const CustomerMail = () => {
  return (
    <div className="page-container">
      <SearchForm />
      <Emails />
    </div>
  );
};

export default CustomerMail;
