import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import pageReducer from "./reducers/pageReducer";
import deviceReducer from "./reducers/deviceReducer";
import unlocatedAddressReducer from "./reducers/unlocatedAddressReducer";
import successMessageReducer from "./reducers/successMessageReducer";
import errorReducer from "./actions/errorReducer";

const middleware = [thunk];

const initialState = {};

const reducers = combineReducers({
  pageData: pageReducer,
  deviceData: deviceReducer,
  unlocatedAddressData: unlocatedAddressReducer,
  successMessageData: successMessageReducer,
  errorData: errorReducer,
});

let store = createStore(
  reducers,
  initialState,
  compose(applyMiddleware(...middleware))
);

export default store;
