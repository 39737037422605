import React, { useEffect } from "react";

import "./App.css";

import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import store from "./redux/store";
import NoMatch from "./pages/NoMatch";
import Navbar from "./components/nav-bar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import AboutMe from "./pages/AboutMe";
import Projects from "./pages/Projects";
import CustomerMail from "./pages/CustomerMail";

import axios from "axios";
import { SET_DEVICESIZE } from "./redux/types";
import { setDeviceCountry } from "./redux/actions/deviceActions";
import FDX from "./pages/FDX";
import UnlocatedAddresses from "./pages/UnlocatedAddresses";
import AOP from "./pages/AOP";
import AOPDriver from "./pages/AOPDriver";
import AOPManger from "./pages/AOPManager";

axios.defaults.baseURL =
  "https://usuz7zgm52.execute-api.us-east-1.amazonaws.com/v1/";

if (process.env.NODE_ENV === "development")
  axios.defaults.baseURL = "http://localhost:8080/";

function App() {
  function reportWindowSize() {
    store.dispatch({ type: SET_DEVICESIZE });
  }

  useEffect(() => {
    reportWindowSize();
    window.addEventListener("resize", reportWindowSize);

    var requestUrl =
      "https://api.ipdata.co?api-key=89e03eaa98dc1e690e8a65ba8fb5db1a86f6ed946f672a0c4885c153";
    axios.get(requestUrl).then((res) => {
      store.dispatch(setDeviceCountry(res.data.country_code));
    });
    return () => window.removeEventListener("resize", reportWindowSize);
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={() => <Home />} />
          <Route exact path="/about-me" component={() => <AboutMe />} />
          <Route exact path="/projects" component={() => <Projects />} />
          <Route exact path="/fdx" component={() => <FDX />} />
          <Route
            exact
            path="/unlocated-addresses"
            component={() => <UnlocatedAddresses />}
          />
          <Route
            exact
            path="/customer-mails"
            component={() => <CustomerMail />}
          />

          <Route exact path="/AOP" component={() => <AOP />} />
          <Route exact path="/AOP/driver" component={() => <AOPDriver />} />
          <Route exact path="/AOP/manager" component={() => <AOPManger />} />
          <Route component={NoMatch} />
        </Switch>
        <Footer />
      </Router>
    </Provider>
  );
}

export default App;
