/* eslint-disable import/no-anonymous-default-export */
import { CLEAR_ERRORS, SET_ERRORS } from "../types";

const initialState = {
  errors: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ERRORS:
      if (
        action.payload.message &&
        action.payload.message === "Please sign in or sign up!"
      ) {
        setTimeout(
          () => (window.location.href = window.location.origin + "/sign-in"),
          1000
        );
        return { ...state, errors: action.payload };
      }
      return { ...state, errors: action.payload };
    case CLEAR_ERRORS: {
      return { ...state, errors: {} };
    }
    default:
      return state;
  }
};
