import React from "react";

import "./PricingContainer.styles.scss";
import "../../pages/pages.styles.scss";
import AnimationContainer from "../translate-animation-container/AnimationContainer";
import { connect } from "react-redux";
import OpacityTranslateAnimationContainer from "../translate-animation-container/OpacityTranslateAnimationContainer";

const pricingRates = [
  {
    name: "Personal",
    availability: "Available",
    priceUS: "Start From $20 Per Month",
    priceMM: "Start From 20000 Kyats Per Month",
    services: [
      "Free Custom Design",
      "SSL Security",
      "Unlimited Bandwidth and Storage",
      "Mobile Optimized Websites",
      "24/7 Customer Support",
    ],
  },
  {
    name: "Small Business",
    availability: "Available",
    price: "Start From 40000 Kyats Per Month",
    priceUS: "Start From $100 Per Month",
    priceMM: "Start From 40000 Kyats Per Month",
    services: [
      "Free Custom Design",
      "SSL Security",
      "Unlimited Bandwidth and Storage",
      "Mobile Optimized Websites",
      "24/7 Customer Support",
      "Customizable products (Price, Image, Description)",
    ],
  },
  {
    name: "Commerce Business",
    availability: "Coming Soon",
    priceUS: "Start From $500 Per Month",
    priceMM: "Start From 100000 Kyats Per Month",
    services: [
      "Free Custom Design",
      "SSL Security",
      "Unlimited Bandwidth and Storage",
      "Mobile Optimized Websites",
      "24/7 Customer Support",
      "Customizable products (Price, Image, Description)",
      "Fully Integrated E-Commerce",
      "Gift Cards",
      "Customer Account",
      "Online Payment",
      "Shipping",
    ],
  },
];

const PricingContainer = ({ deviceCountry }) => {
  return (
    <div className="pricing-container">
      <h1 className="container-title">Pricing</h1>
      <div className="flex-container">
        {pricingRates.map((rate, i) => (
          <OpacityTranslateAnimationContainer key={i} animationDelay={i * 500}>
            <div className="pricing-item">
              <div>
                <h2 align="center">{rate.name}</h2>
                <h3 align="center">{rate.availability}</h3>
              </div>
              <p align="center">
                {deviceCountry === "US" ? rate.priceUS : rate.priceMM}
              </p>
              <ul className="service-list">
                {rate.services.map((service) => (
                  <li key={service}>{service}</li>
                ))}
              </ul>
            </div>
          </OpacityTranslateAnimationContainer>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deviceCountry: state.deviceData.deviceCountry,
});
export default connect(mapStateToProps)(PricingContainer);
