import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getPageLoading } from "../../redux/actions/pageActions";
import AnimatedDot from "../animated-dot/AnimatedDot";

import "./PageLoading.styles.scss";

const PageLoading = ({
  loading,
  getPageLoading,
  imageUrl,
  imageUrlMobile,
  deviceWidth,
  children,
}) => {
  useEffect(() => {
    if (loading)
      getPageLoading(
        deviceWidth > 900
          ? imageUrl
          : imageUrlMobile
          ? imageUrlMobile
          : imageUrl
      );
  }, [deviceWidth]);
  return (
    <Fragment>
      {loading ? (
        <div className="page-loading-container">
          <div className="page-loading-content-container">
            <h1 align="center">MK Web Developer</h1>
            <AnimatedDot />
          </div>
        </div>
      ) : (
        <div className="page-container">
          <Fragment>{children}</Fragment>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.pageData.loading,
  deviceWidth: state.deviceData.deviceWidth,
});

export default connect(mapStateToProps, { getPageLoading })(PageLoading);
