import React, { useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { objToString, stringToObj } from "../../utils/utilsFunc";

import "./SearchForm.styles.scss";

const SearchForm = ({ history }) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    const pageSearch = stringToObj(history.location.search).search;
    if (pageSearch) setSearch(pageSearch.replace(/%20/g, " "));
  }, [history.location.search]);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let queryObj = {};
    if (history.location.search !== "?") {
      queryObj = stringToObj(history.location.search);
    }
    queryObj.search = search;
    queryObj.page = 1;
    if (!queryObj.search) delete queryObj.search;
    history.push({ search: objToString(queryObj) });
  };
  return (
    <form className="search-form">
      <input
        className="search-input"
        value={search}
        type="text"
        placeholder="Search.."
        onChange={handleInputChange}
      />
      <button className="search-btn" onClick={handleFormSubmit}>
        <span className="material-icons" style={{ paddingTop: 5 }}>
          search
        </span>
      </button>
    </form>
  );
};

export default withRouter(SearchForm);
