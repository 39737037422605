import React, { createRef, useState, useEffect } from "react";
import { Fragment } from "react";

import { getPosition } from "../../utils/utilsFunc";

const AnimationContainer = ({
  children,
  beforeAnimationStyle,
  animationStyle,
  adjustedScrollHeight,
  animationDelay,
}) => {
  const container = createRef();
  const [isReached, setIsReached] = useState(false);
  const [windowScroll, setWindowScroll] = useState(0);
  const [currentEle, setCurrentEle] = useState(null);
  const [elementYPosition, setElementYPosition] = useState(0);
  const [style, setStyle] = useState(beforeAnimationStyle);

  useEffect(() => {
    if (isReached) setStyle({ ...style, ...animationStyle });
    else setStyle(beforeAnimationStyle);
  }, [isReached, animationStyle, beforeAnimationStyle]);

  useEffect(() => {
    setCurrentEle(container.current);
    setElementYPosition(getPosition(currentEle).y);
    let y = window.scrollY + window.innerHeight;
    if (adjustedScrollHeight) y -= adjustedScrollHeight;
    if (y > elementYPosition && elementYPosition !== 0) {
      if (animationDelay) {
        setTimeout(() => setIsReached(true), animationDelay);
      } else setIsReached(true);
    }
    window.addEventListener("scroll", () => {
      y = window.scrollY + window.innerHeight;
      if (adjustedScrollHeight) y -= adjustedScrollHeight;
      setWindowScroll(y);

      if (y > elementYPosition && elementYPosition !== 0) {
        if (animationDelay) {
          setTimeout(() => setIsReached(true), animationDelay);
        } else setIsReached(true);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {
        const y = window.scrollY + window.innerHeight;
        setWindowScroll(y);
        if (y > elementYPosition) {
          if (animationDelay) {
            setTimeout(() => setIsReached(true), animationDelay);
          } else setIsReached(true);
        }
      });
    };
  }, [
    windowScroll,
    container,
    currentEle,
    elementYPosition,
    adjustedScrollHeight,
    animationDelay,
  ]);

  return (
    <div style={style} ref={container}>
      {children}
    </div>
  );
};

export default AnimationContainer;
