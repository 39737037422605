import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/form/Form.styles";
import "./pages.styles.scss";

const AOP = () => {
  return (
    <div className="page-container">
      <br />
      <Button>
        <Link
          to="/AOP/driver"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Driver
        </Link>
      </Button>
      <br />
      <Button>
        <Link
          to="/AOP/manager"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          Manager
        </Link>
      </Button>
    </div>
  );
};

export default AOP;
