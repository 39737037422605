import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import { FormSelect } from "../components/form/Form";
import { Button } from "../components/form/Form.styles";
import LoadingCircle from "../components/loading-circle/LoadingCircle";
import AddUnlocatedAddressModal from "../components/modal/add-unlocated-address/AddUnlocatedAddressModal";
import ConfirmModal from "../components/modal/ConfirmModal";
import SearchForm from "../components/search-form/SearchForm";
import SuccessMessageContainer from "../components/success-message-container/SuccessMessageContainer";
import OpacityTranslateAnimationContainer from "../components/translate-animation-container/OpacityTranslateAnimationContainer";
import UnlocatedAddressRow from "../components/unlocated-address-row/UnlocatedAddressRow";
import {
  deleteUnlocatedAddress,
  getUnlocatedAddresses,
  sortUnlocatedAddresses,
} from "../redux/actions/unlocatedAddressActions";
import { SEARCHKEY, STREETNAME } from "../redux/types";

const contractorNames = [
  "AOP",
  "Barrington Logistics",
  "Edward Steward",
  "VIK",
  "PCBE",
  "DALI",
  "SALY",
  "CAMD",
];
const triggerWidth = 900;
const UnlocatedAddresses = ({
  getUnlocatedAddresses,
  deleteUnlocatedAddress,
  unlocatedAddresses,
  loading,
  updateLoading,
  sortUnlocatedAddresses,
  deviceWidth,
  successMessage,
}) => {
  document.title = "Unlocated Addresses";
  const [search, setSearch] = useState("");
  const [unlocatedAddressesBySearch, setUnlocatedAddressesBySearch] = useState(
    []
  );
  const [sortType, setSortType] = useState(STREETNAME);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editAddress, setEditAddress] = useState({});

  useEffect(() => {
    getUnlocatedAddresses();
  }, []);

  const getDataBySearch = (str) => {
    setSearch(str);
    setUnlocatedAddressesBySearch(
      unlocatedAddresses.filter(
        (add) =>
          add.streetName.toLowerCase().includes(str.toLowerCase()) ||
          add.zipcode.includes(str)
      )
    );
  };

  const noAddressFoundRow = (
    <tr>
      <td
        colSpan="3"
        style={{
          padding: 4,
          textAlign: "center",
          border: "1px solid #999",
        }}
      >
        No Address Found!!!
      </td>
      {deviceWidth > triggerWidth && <td style={{ width: 100 }}></td>}
    </tr>
  );

  const handleHideModal = (e) => {
    if (e) e.preventDefault();
    setShowModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
  };

  const handleEdit = (address) => {
    setShowEditModal(true);
    setEditAddress(address);
  };

  const handleDelete = (address) => {
    setShowDeleteModal(true);
    setEditAddress(address);
  };

  const handleDeleteUnlocatedAddress = (e, id) => {
    e.preventDefault();
    deleteUnlocatedAddress(editAddress._id);
  };

  const handleSort = (e) => {
    if (e.target.value !== sortType) {
      setSortType(e.target.value);
      sortUnlocatedAddresses(e.target.value, unlocatedAddresses);
    }
  };

  return (
    <div className="page-container">
      <OpacityTranslateAnimationContainer>
        <SearchForm getdataBySearch={getDataBySearch} />
      </OpacityTranslateAnimationContainer>
      <OpacityTranslateAnimationContainer animationDelay={300}>
        <div className="unlocated-address-sort-container">
          <p style={{ width: 85, marginRight: 10 }}>Sort By </p>
          <FormSelect
            style={{ width: 200, padding: 0 }}
            options={[STREETNAME, SEARCHKEY]}
            onChange={handleSort}
            noError
          />
        </div>
      </OpacityTranslateAnimationContainer>
      {deviceWidth > triggerWidth && (
        <OpacityTranslateAnimationContainer animationDelay={600}>
          <Button onClick={() => setShowModal(true)}>Add</Button>
        </OpacityTranslateAnimationContainer>
      )}

      {loading ? (
        <OpacityTranslateAnimationContainer animationDelay={900}>
          <div style={{ paddingTop: 100 }}>
            <LoadingCircle size={75} />
          </div>
        </OpacityTranslateAnimationContainer>
      ) : (
        <OpacityTranslateAnimationContainer animationDelay={1200}>
          <div className="unlocated-addresses-container">
            {contractorNames.map((name, i) => (
              <OpacityTranslateAnimationContainer animationDelay={i * 300}>
                <table key={name} className="unlocated-address-table">
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: 4,
                          textAlign: "center",
                          backgroundColor: "#FF6200",
                          color: "white",
                        }}
                        colSpan={deviceWidth > triggerWidth ? "3" : "2"}
                      >
                        {name}
                      </th>
                      {deviceWidth > triggerWidth && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {search ? (
                      <Fragment>
                        {unlocatedAddressesBySearch.filter(
                          (add) => add.contractorName === name
                        ).length > 0 ? (
                          <Fragment>
                            {unlocatedAddressesBySearch
                              .filter((add) => add.contractorName === name)
                              .map((add, i) => (
                                <UnlocatedAddressRow
                                  triggerWidth={triggerWidth}
                                  handleDelete={handleDelete}
                                  handleEdit={handleEdit}
                                  key={`${add._id}${add.streetName}${
                                    add.routeNo ? add.routeNo : ""
                                  }`}
                                  index={i}
                                  address={add}
                                />
                              ))}
                          </Fragment>
                        ) : (
                          <Fragment>{noAddressFoundRow}</Fragment>
                        )}
                      </Fragment>
                    ) : unlocatedAddresses.filter(
                        (add) => add.contractorName === name
                      ).length > 0 ? (
                      <Fragment>
                        {unlocatedAddresses
                          .filter((add) => add.contractorName === name)
                          .map((add, i) => (
                            <UnlocatedAddressRow
                              triggerWidth={triggerWidth}
                              handleDelete={handleDelete}
                              handleEdit={handleEdit}
                              key={`${add._id}${add.streetName}${
                                add.routeNo ? add.routeNo : ""
                              }`}
                              index={i}
                              address={add}
                            />
                          ))}
                      </Fragment>
                    ) : (
                      <Fragment>{noAddressFoundRow}</Fragment>
                    )}
                  </tbody>
                </table>
              </OpacityTranslateAnimationContainer>
            ))}
          </div>
        </OpacityTranslateAnimationContainer>
      )}
      {showModal && (
        <AddUnlocatedAddressModal
          contractorNames={contractorNames}
          handleCancel={handleHideModal}
        />
      )}
      {showEditModal && (
        <AddUnlocatedAddressModal
          edit={true}
          unlocatedAddress={editAddress}
          contractorNames={contractorNames}
          handleCancel={handleHideModal}
        />
      )}
      {showDeleteModal && (
        <ConfirmModal
          title={`Do you wan to delete ${editAddress.streetName.toUpperCase()}`}
          loading={updateLoading}
          handleSubmit={handleDeleteUnlocatedAddress}
          handleCancel={handleHideModal}
        />
      )}
      {successMessage && (
        <SuccessMessageContainer
          key={successMessage}
          timeout={successMessage === "Copied" ? 1000 : null}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  updateLoading: state.unlocatedAddressData.updateLoading,
  unlocatedAddresses: state.unlocatedAddressData.unlocatedAddresses,
  loading: state.unlocatedAddressData.loading,
  successMessage: state.successMessageData.successMessage,
  deviceWidth: state.deviceData.deviceWidth,
});
export default connect(mapStateToProps, {
  deleteUnlocatedAddress,
  sortUnlocatedAddresses,
  getUnlocatedAddresses,
})(UnlocatedAddresses);
