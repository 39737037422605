import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./BarChart.styles.scss";
import { connect } from "react-redux";

const BarChart = ({ data, deviceWidth }) => {
  const ref = useRef();
  useEffect(() => {}, [deviceWidth]);

  useEffect(() => {
    let height = 500;
    let width = 1000;
    if (window.innerWidth < 500) {
      width = window.innerWidth;
    }

    ref.current.innerHTML = "";
    if (
      data.some((a) => {
        return a.male > 0;
      })
    ) {
      let svg = d3.select(ref.current);
      const colors = ["rgb(96, 120, 255)", "rgb(255, 96, 96)"];
      const xMaleValue = (d) => d["male"];
      const xFemaleValue = (d) => d["female"];
      const yValue = (d) => d.name;
      let margin = { top: 50, right: 40, bottom: 40, left: 140 };
      let yAxisLabelYOffset = -20;
      if (window.innerWidth < 600) {
        yAxisLabelYOffset = -10;
        margin = { top: 20, right: 20, bottom: 30, left: 100 };
      }
      svg.attr("width", width).attr("height", height);
      const innerWidth = width - margin.left - margin.right;
      const innerHeight = height - margin.top - margin.bottom;

      const xScale = d3.scaleLinear().domain([0, 100]).range([0, innerWidth]);

      const yScale = d3
        .scaleBand()
        .domain(data.map(yValue))
        .range([0, innerHeight])
        .padding(0.1);

      const g = svg
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const xAxisTickFormat = (number) =>
        d3.format(".0s")(number).replace("G", "B");

      const xAxis = d3
        .axisBottom(xScale)
        .tickFormat(xAxisTickFormat)
        .tickSize(-innerHeight);

      const yAxisG = g
        .append("g")
        .call(d3.axisLeft(yScale))
        .selectAll(".domain")
        .remove();

      g.selectAll("text")
        .attr("text-anchor", "mid")
        .attr("class", "y-axis-label-font-size")
        .call(function (t) {
          t.each(function (d) {
            // for each one
            let self = d3.select(this);
            var s = self.text().split(","); // get the text and split it
            // clear it out
            if (s.length > 1) {
              self.text("");
              // self.append("tspan") // insert two tspans
              //     .attr("x", "0")
              //     .attr("y", "7")
              //     .attr("dy", "1em")
              //     .text(s[0]);
              // self.append("tspan")
              //     .attr("x", "0")
              //     .attr("dy", "1em")
              //     .text(s[1]);
              self
                .append("tspan") // insert two tspans
                .attr("x", "-10")
                .attr("y", yAxisLabelYOffset)
                .attr("dy", "1em")
                .text(s[0]);
              self
                .append("tspan")
                .attr("x", "-10")
                .attr("dy", "1em")
                .text(s[1]);
            } else {
              // self.attr("y", -10);
              self.attr("y", 0);
            }
          });
        });

      const xAxisG = g
        .append("g")
        .call(xAxis)
        .attr("transform", `translate(0,${innerHeight})`);

      // xAxisG.select(".domain").remove();

      xAxisG
        .selectAll("text")
        .attr("class", "x-axis-label")
        .attr("x", "19")
        .attr("y", "-")
        .call(function (t) {
          t.each(function (d) {
            // for each one
            let self = d3.select(this);
            if (d % 20 !== 0) self.remove();
          });
        });
      xAxisG
        .selectAll("line")
        .attr("class", "x-axis-line")
        .call(function (t) {
          t.each(function (d) {
            // for each one
            let self = d3.select(this);
            if (d % 20 !== 0) self.remove();
          });
        });

      const rect1 = g
        .append("g")
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect");

      const rect2 = g
        .append("g")
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect");

      rect1
        .attr("x", function (d) {
          return xScale(xFemaleValue(d));
        })
        .transition()
        .duration(2000)
        .delay(1500)
        .attr("width", (d) => xScale(xMaleValue(d)));

      rect1
        .attr("fill", colors[0])
        .attr("y", (d) => yScale(yValue(d)))
        .attr("height", yScale.bandwidth());

      rect2
        .transition()
        .duration(1000)
        .attr("width", (d) => xScale(xFemaleValue(d)));

      rect2
        .attr("fill", colors[1])
        .attr("y", (d) => yScale(yValue(d)))
        .attr("height", yScale.bandwidth());
    }
  }, [data, deviceWidth]);
  return <svg ref={ref} className="bar-chart-container"></svg>;
};

const mapStateToProps = (state) => ({
  deviceWidth: state.deviceData.deviceWidth,
});
export default connect(mapStateToProps)(BarChart);
