import React, { useEffect } from "react";
import "./pages.styles.scss";
import MapContainer from "../components/map-container/MapContainer";
import ContactForm from "../components/contact-form/ContactForm";
import OpacityTranslateAnimationContainer from "../components/translate-animation-container/OpacityTranslateAnimationContainer";
import SuccessMessageContainer from "../components/success-message-container/SuccessMessageContainer";

const AboutMe = () => {
  document.title = "About Me";
  const imageUrl =
    "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fprofile%2Fprofile-pic.jpg?alt=media&token=47308f74-3122-4b8f-859d-ab106916fcfa";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-container">
      <SuccessMessageContainer />
      <div className="profile-container">
        <OpacityTranslateAnimationContainer>
          <div className="profile-picture-container">
            <div className="profile-picture-loading"></div>
            <img
              className="profile-picture"
              src={imageUrl}
              id="bg"
              alt="profile"
              loading="lazy"
            />
          </div>
        </OpacityTranslateAnimationContainer>
        <div className="profile-description">
          <OpacityTranslateAnimationContainer animationDelay={300}>
            <h1>Min Khant Kyaw</h1>
          </OpacityTranslateAnimationContainer>
          <OpacityTranslateAnimationContainer animationDelay={600}>
            <h3>Full Stack Web Developer</h3>
          </OpacityTranslateAnimationContainer>
          <OpacityTranslateAnimationContainer animationDelay={900}>
            <p>
              Creative and self-starting engineer with a background in
              engineering, and designing construction projects. Strong skills in
              project design cycles for both IT and construction projects.
              Experience in managing operations working in fast-paced and
              collaborative environments. Highly skilled in
              HTML/CSS/Javascript/React/React Native/NodeJS/Java/Android
              Development and working knowledge.
            </p>
          </OpacityTranslateAnimationContainer>
        </div>
      </div>

      <OpacityTranslateAnimationContainer>
        <div className="map-container">
          <h1 className="container-title">Location of Our Company</h1>
          <MapContainer />
        </div>
      </OpacityTranslateAnimationContainer>
      <OpacityTranslateAnimationContainer>
        <ContactForm />
      </OpacityTranslateAnimationContainer>
    </div>
  );
};

export default AboutMe;
