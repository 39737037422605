import React from "react";
import "./LoadingCircle.styles.scss";

const LoadingCircle = ({ size }) => {
  const loaderStyle = {
    border: `${size > 20 ? 9 : 3}px solid #f3f3f3`,
    borderTop: `${size > 20 ? 10 : 5}px solid #033a5f`,
    width: size,
    height: size,
  };
  return (
    <div
      style={{
        transform: `translate(calc(50% - ${size / 2}px), calc(50% - ${
          size / 2
        }px))`,
      }}
    >
      <div className="loader" style={loaderStyle}></div>
    </div>
  );
};

export default LoadingCircle;
