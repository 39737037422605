import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { clearSuccessMessage } from "../../redux/actions/successMessageActions";
import "./SuccessMessageContainer.styles.scss";

const SuccessMessageContainer = ({
  timeout,
  clearSuccessMessage,
  successMessage,
  redirect,
  noAlert,
}) => {
  useEffect(() => {
    if (successMessage && !noAlert) {
      setTimeout(
        () => {
          clearSuccessMessage(redirect);
        },
        timeout ? timeout : 1000
      );
    }
  }, [successMessage]);

  return (
    <Fragment>
      {successMessage && !noAlert ? (
        <div className="success-message-container">
          <div className="success-message">
            <p>{successMessage}</p>
          </div>
        </div>
      ) : (
        <p style={{ color: "green" }}>{successMessage}</p>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  successMessage: state.successMessageData.successMessage,
});
export default connect(mapStateToProps, { clearSuccessMessage })(
  SuccessMessageContainer
);
