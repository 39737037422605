import {
  SET_UNLOCATEDADDRESSLOADING,
  STOP_UNLOCATEDADDRESSLOADING,
  STOP_UNLOCATEDADDRESSUPDATELOADING,
  SET_UNLOCATEDADDRESSES,
  SET_UNLOCATEDADDRESS,
  SET_UNLOCATEDADDRESSUPDATELOADING,
  CLEAR_CURRENTUNLOCATEDADDRESS,
  STOP_UNLOCATEDADDRESSPAGESLOADING,
  SET_UNLOCATEDADDRESSPAGESLOADING,
  SET_UNLOCATEDADDRESSPAGES,
  ADD_UNLOCATEDADDRESS,
  DELETE_UNLOCATEDADDRESS,
  UPDATE_UNLOCATEDADDRESS,
  UPDATE_UNLOCATEDADDRESSROUTENO,
  STOP_UPDATEUNLOCATEDADDRESSESLOADING,
  SET_UPDATEUNLOCATEDADDRESSESLOADING,
} from "../types";

const initialState = {
  unlocatedAddresses: [],
  loading: false,
  updateLoading: false,
  currentUnlocatedAddress: {},
  totalPages: 0,
  pagesLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  let index;
  let unlocatedAddresses;
  switch (action.type) {
    case SET_UNLOCATEDADDRESSLOADING:
      return { ...state, loading: true };
    case STOP_UNLOCATEDADDRESSLOADING:
      return { ...state, loading: false };
    case STOP_UPDATEUNLOCATEDADDRESSESLOADING:
      return { ...state, updateLoading: false };
    case SET_UPDATEUNLOCATEDADDRESSESLOADING:
      return { ...state, updateLoading: true };
    case SET_UNLOCATEDADDRESSPAGESLOADING:
      return { ...state, pagesLoading: true };
    case STOP_UNLOCATEDADDRESSPAGESLOADING:
      return { ...state, pagesLoading: false };
    case CLEAR_CURRENTUNLOCATEDADDRESS:
      return { ...state, currentUnlocatedAddress: {} };
    case SET_UNLOCATEDADDRESSUPDATELOADING:
      return { ...state, updateLoading: true };
    case STOP_UNLOCATEDADDRESSUPDATELOADING:
      return { ...state, updateLoading: false };
    case SET_UNLOCATEDADDRESSPAGES:
      return {
        ...state,
        totalPages: action.payload,
        pagesLoading: false,
      };
    case SET_UNLOCATEDADDRESSES:
      return {
        ...state,
        unlocatedAddresses: action.payload,
        loading: false,
      };
    case SET_UNLOCATEDADDRESS:
      return {
        ...state,
        currentUnlocatedAddress: action.payload,
        loading: false,
      };
    case ADD_UNLOCATEDADDRESS:
      unlocatedAddresses = [action.payload, ...state.unlocatedAddresses];
      return {
        ...state,
        unlocatedAddresses: unlocatedAddresses,
        updateLoading: false,
      };
    case DELETE_UNLOCATEDADDRESS:
      unlocatedAddresses = [...state.unlocatedAddresses];
      index = unlocatedAddresses.findIndex(
        (item) => item.id === action.payload
      );
      if (index !== -1) {
        unlocatedAddresses.splice(index, 1);
      }
      return {
        ...state,
        unlocatedAddresses: unlocatedAddresses,
        updateLoading: false,
      };
    case UPDATE_UNLOCATEDADDRESS:
      unlocatedAddresses = [...state.unlocatedAddresses];
      index = unlocatedAddresses.findIndex((item) => {
        return item.id === action.payload.id;
      });
      if (index !== -1) {
        unlocatedAddresses[index] = action.payload;
      }
      return {
        ...state,
        unlocatedAddresses: unlocatedAddresses,
        updateLoading: false,
      };
    case UPDATE_UNLOCATEDADDRESSROUTENO:
      unlocatedAddresses = [...state.unlocatedAddresses];
      unlocatedAddresses = unlocatedAddresses.map((item) => {
        if (item.searchKey === action.payload.searchKey)
          return { ...item, routeNo: action.payload.routeNo };
        return item;
      });
      return {
        ...state,
        unlocatedAddresses: unlocatedAddresses,
        updateLoading: false,
      };
    default:
      return state;
  }
};
