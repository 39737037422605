import axios from "axios";

import {
  SET_UNLOCATEDADDRESSLOADING,
  STOP_UNLOCATEDADDRESSLOADING,
  STOP_UNLOCATEDADDRESSUPDATELOADING,
  SET_UNLOCATEDADDRESSES,
  SET_UNLOCATEDADDRESS,
  UPDATE_UNLOCATEDADDRESS,
  SET_UNLOCATEDADDRESSUPDATELOADING,
  UPDATE_UNLOCATEDADDRESSROUTENO,
  SET_ERRORS,
  CLEAR_CURRENTUNLOCATEDADDRESS,
  SET_UPDATEUNLOCATEDADDRESSESLOADING,
  STOP_UPDATEUNLOCATEDADDRESSESLOADING,
  STOP_UNLOCATEDADDRESSPAGESLOADING,
  SET_UNLOCATEDADDRESSPAGESLOADING,
  SET_UNLOCATEDADDRESSPAGES,
  ADD_UNLOCATEDADDRESS,
  SET_SUCCESSMESSAGE,
  DELETE_UNLOCATEDADDRESS,
  STREETNAME,
  SEARCHKEY,
} from "../types";



const pathname = "unlocated-addresses";
export const getTotalUnlocatedAddressCount = (filter, search) => (dispatch) => {
  dispatch({ type: SET_UNLOCATEDADDRESSLOADING });

  let url = pathname + `?`;
  if (filter) url += filter;
  if (search) url += "&search=" + search;
  axios
    .get(url)
    .then((res) => {
      dispatch({ type: SET_UNLOCATEDADDRESSES, payload: res.data.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: STOP_UNLOCATEDADDRESSLOADING });
      dispatch({ type: SET_ERRORS, payload: err.response.data });
    });
};

export const getUnlocatedAddressPages = (limit, queryString) => (dispatch) => {
  dispatch({ type: SET_UNLOCATEDADDRESSPAGESLOADING });
  let url = pathname + `/pages?limit=${limit ? limit : 15}`;
  if (queryString) url += "&" + queryString.replace("?", "");
  axios
    .get(url)
    .then((res) => {
      dispatch({ type: SET_UNLOCATEDADDRESSPAGES, payload: res.data.pageNo });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: STOP_UNLOCATEDADDRESSPAGESLOADING });
      dispatch({ type: SET_ERRORS, payload: err.response.data });
    });
};

export const getUnlocatedAddresses = (limit, queryString) => (dispatch) => {
  dispatch({ type: SET_UNLOCATEDADDRESSLOADING });

  let url = pathname + `?limit=200&sort=streetName`;
  axios
    .get(url)
    .then((res) => {
      dispatch({ type: SET_UNLOCATEDADDRESSES, payload: res.data.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: STOP_UNLOCATEDADDRESSLOADING });
      dispatch({ type: SET_ERRORS, payload: "Something went wrong" });
    });
};

export const sortUnlocatedAddresses =
  (sortType, unlocatedAddresses) => (dispatch) => {
    dispatch({ type: SET_UNLOCATEDADDRESSLOADING });

    let url = pathname + `?limit=200&sort=streetName`;
    let sortedAddresses;
    switch (sortType) {
      case STREETNAME:
        sortedAddresses = unlocatedAddresses.sort((x, y) => {
          return x.streetName.localeCompare(y.streetName);
        });
        break;
      case SEARCHKEY:
        sortedAddresses = unlocatedAddresses.sort((x, y) => {
          return x.searchKey.localeCompare(y.searchKey);
        });
        break;
      default:
        break;
    }

    dispatch({ type: SET_UNLOCATEDADDRESSES, payload: sortedAddresses });
  };

export const getUnlocatedAddressById = (id) => (dispatch) => {
  dispatch({ type: SET_UNLOCATEDADDRESSLOADING });
  let url = pathname + `/${id}`;
  axios
    .get(url)
    .then((res) => {
      dispatch({ type: SET_UNLOCATEDADDRESS, payload: res.data.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: STOP_UNLOCATEDADDRESSLOADING });
      dispatch({ type: SET_ERRORS, payload: err.response.data });
    });
};

export const updateUnlocatedAddress = (id, updateData) => (dispatch) => {
  dispatch({ type: SET_UPDATEUNLOCATEDADDRESSESLOADING });
  let url = pathname + `/${id}`;
  axios
    .patch(url, updateData)
    .then((res) => {
      dispatch({ type: UPDATE_UNLOCATEDADDRESS, payload: res.data.data });
      dispatch({ type: SET_SUCCESSMESSAGE, payload: "Sucessfully updated" });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: STOP_UPDATEUNLOCATEDADDRESSESLOADING });
      dispatch({ type: SET_ERRORS, payload: err.response.data });
    });
};

export const updateRouteNoBySearchKey = (searchKey, routeNo) => (dispatch) => {
  dispatch({ type: SET_UPDATEUNLOCATEDADDRESSESLOADING });
  let url = pathname + `?searchKey=${searchKey}`;
  axios
    .patch(url, { routeNo })
    .then((res) => {
      dispatch({
        type: SET_SUCCESSMESSAGE,
        payload: "Route No. sucessfully  updated",
      });

      dispatch({
        type: UPDATE_UNLOCATEDADDRESSROUTENO,
        payload: { searchKey, routeNo },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: STOP_UPDATEUNLOCATEDADDRESSESLOADING });
      dispatch({ type: SET_ERRORS, payload: err.response.data });
    });
};

export const clearCurrentUnlocatedAddress = () => (dispatch) => {
  dispatch({ type: CLEAR_CURRENTUNLOCATEDADDRESS });
};

export const addUnlocatedAddress = (newUnlocatedAddressItem) => (dispatch) => {
  dispatch({ type: SET_UNLOCATEDADDRESSUPDATELOADING });
  let url = pathname + ``;

  axios
    .post(url, newUnlocatedAddressItem, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({ type: SET_SUCCESSMESSAGE, payload: "Successfully added" });
      dispatch({
        type: ADD_UNLOCATEDADDRESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({ type: STOP_UNLOCATEDADDRESSUPDATELOADING });
      dispatch({ type: SET_ERRORS, payload: err.response.data });
    });
};

export const deleteUnlocatedAddress = (id) => (dispatch) => {
  dispatch({ type: SET_UPDATEUNLOCATEDADDRESSESLOADING });
  let url = pathname + `/${id}`;
  axios
    .delete(url)
    .then((res) => {
      dispatch({ type: DELETE_UNLOCATEDADDRESS, payload: id });
      dispatch({ type: SET_SUCCESSMESSAGE, payload: "Sucessfully deleted" });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: STOP_UPDATEUNLOCATEDADDRESSESLOADING });
      dispatch({ type: SET_ERRORS, payload: err.response.data });
    });
};

export const clearLoading = () => (dispatch) => {
  dispatch({ type: STOP_UNLOCATEDADDRESSLOADING });
  dispatch({ type: STOP_UNLOCATEDADDRESSUPDATELOADING });
};
