import React from "react";

import {
  HeaderContainer,
  LogoContainer,
  OptionsContainer,
  OptionLink,
} from "./Navbar.styles";

import Logo from "../logo/Logo";
import { withRouter } from "react-router-dom";

const NavBar = ({ history, match }) => {
  const navLinks = [
    { name: "Home", pathname: "/" },
    { name: "About Me", pathname: "/about-me" },
    { name: "Projects", pathname: "/projects" },
  ];
  return (
    <HeaderContainer>
      <LogoContainer to="/">
        <Logo />
      </LogoContainer>
      <OptionsContainer>
        {navLinks.map((navLink, i) => {
          if (navLink.pathname === history.location.pathname)
            return (
              <OptionLink key={i} active={true} to={navLink.pathname}>
                {navLink.name}
              </OptionLink>
            );
          return (
            <OptionLink key={i} to={navLink.pathname}>
              {navLink.name}
            </OptionLink>
          );
        })}
      </OptionsContainer>
    </HeaderContainer>
  );
};

export default withRouter(NavBar);
