import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderContainer = styled.div`
  height: 80px;
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #487eb0;
  position: fixed;
  top: 0;
  background-color: rgba(25, 42, 86);
  z-index: 10;
  @media (max-width: 850px) {
    flex-direction: column;
    height: 90px;
  }
`;

export const LogoContainer = styled(Link)`
  width: 45%;
  padding: 10px 20px;
  text-decoration: none;

  @media (max-width: 850px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const OptionsContainer = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 850px) {
    width: 100%;
    border-top: 1px solid #f0d9ff;
    justify-content: space-around;
  }
`;

export const OptionLink = styled(Link)`
  padding: 0px 15px;
  cursor: pointer;
  font-size: 1.5rem;
  text-decoration: none;
  ${({ active }) =>
    active
      ? `
    color: #00CCCD;
  `
      : `color: #fff;`}
  @media (max-width: 850px) {
    font-size: 1rem;
  }
`;

export const OptionTabsContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 20px auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 0 2px #ccc;
  @media (max-width: 600px) {
    border-radius: 0;
  }
`;

export const OptionTab = styled.p`
  text-align: center;
  width: 200px;
  padding: 15px 0;
  cursor: pointer;
  ${({ active }) =>
    active
      ? `
    background-color: #333;
    color: #ccc;
  `
      : `background-color: #eee;`}

  border-right: 1px solid #ccc;
  transition: background-color 0.3s ease-in;

  &:last-of-type {
    border: none;
  }

  &:hover {
    color: #eee;
    background-color: #888;
  }

  @media (max-width: 600px) {
    width: 25vw;
  }
`;
