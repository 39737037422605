import React from "react";
import { connect } from "react-redux";

const Logo = ({ deviceWidth }) => {
  const style = {
    color: "#fff",
    fontWeight: 600,
    textAlign: "center",
    fontSize: deviceWidth > 850 ? "3.5rem" : "1.8rem",
    fontFamily: "'Quicksand', sans-serif",
    textShadow: "0px 0px 3px #555",
  };
  return <div style={style}>MK</div>;
};
const mapStateToProps = (state) => ({
  deviceWidth: state.deviceData.deviceWidth,
});

export default connect(mapStateToProps)(Logo);
