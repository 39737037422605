import React, { useState } from "react";

import "./Form.scss";
import {
  InputErrorMsg,
  Input,
  FormInputContainer,
  Button,
  TextArea,
  InputSuccessMsg,
} from "./Form.styles";

export const FormErrorMsg = ({ error, formError }) => (
  <InputErrorMsg>{error ? <p>{error}</p> : null}</InputErrorMsg>
);

export const FormBoxInput = (props) => {
  return (
    <FormInputContainer width={props.width}>
      {props.label && (
        <label
          align="left"
          style={{
            width: "100%",
            opacity: 1,
            transition: "opacity .5s ease",
            display: "block",
          }}
        >
          {props.label}
        </label>
      )}
      <Input {...props} />
      {!props.noError && <FormErrorMsg error={props.error} />}
    </FormInputContainer>
  );
};

export const FormSuccessMsg = ({ style, successMsg }) => (
  <InputSuccessMsg style={style}>
    {successMsg ? <p>{successMsg}</p> : null}
  </InputSuccessMsg>
);
export const SubmitButton = (props) => {
  return <Button {...props}>{props.children}</Button>;
};

export const FormTextArea = (props) => {
  return (
    <FormInputContainer width={props.width}>
      {props.label && (
        <label
          align="left"
          style={{
            width: "100%",
            opacity: 1,
            transition: "opacity .5s ease",
            display: "block",
          }}
        >
          {props.label}
        </label>
      )}
      <div style={{ width: "100%" }}>
        <TextArea {...props}></TextArea>
      </div>
      <FormErrorMsg error={props.error} />
    </FormInputContainer>
  );
};

export const FormSelect = (props) => {
  const [isClicked, setIsClicked] = useState(false);
  const handleSelectClick = (e) => {
    setIsClicked(true);
  };
  return (
    <FormInputContainer width={props.width}>
      {props.label && (
        <label
          align="left"
          style={{
            width: "100%",
            opacity: 1,
            transition: "opacity .5s ease",
            display: "block",
          }}
        >
          {props.label}
        </label>
      )}
      <div style={{ width: "100%" }}>
        <select
          name={props.name}
          className="form-select"
          onChange={props.onChange}
          onClick={handleSelectClick}
        >
          {props.optionTitle && (
            <option
              value="0"
              disabled={isClicked}
              selected={props.optionTitle === props.selectedOption}
            >
              {props.optionTitle}
            </option>
          )}
          {props.options &&
            props.options.length > 0 &&
            props.options.map((option) => (
              <option
                key={option}
                value={option}
                selected={option === props.selectedOption}
              >
                {option}
              </option>
            ))}
        </select>
      </div>
      {!props.noError && <FormErrorMsg error={props.error} />}
    </FormInputContainer>
  );
};
