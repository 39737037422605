import React from "react";
import AnimationContainer from "./AnimationContainer";

const OpacityTranslateAnimationContainer = ({
  style,
  children,
  animationDelay,
  adjustedScrollHeight,
}) => {
  const textAnimationStyle = {
    transform: "translateY(0)",
    opacity: 1,
    ...style,
  };
  const textBeforeAnimationStyle = {
    transform: "translateY(100px)",
    opacity: 0,
    transition: "transform 1s ease, opacity 1s ease",
  };

  return (
    <AnimationContainer
      animationStyle={textAnimationStyle}
      beforeAnimationStyle={textBeforeAnimationStyle}
      animationDelay={animationDelay}
      adjustedScrollHeight={adjustedScrollHeight}
    >
      {children}
    </AnimationContainer>
  );
};

export default OpacityTranslateAnimationContainer;
