import React, { useEffect } from "react";
import "./pages.styles.scss";
import LandingBackground from "../components/landing-background/LandingBackground";
import ProjectsContainer from "../components/projects-container/ProjectsContainer";
import PricingContainer from "../components/pricing-container/PricingContainer";
import PageLoading from "../components/page-loading/PageLoading";
import { connect } from "react-redux";
import { getPageLoading } from "../redux/actions/pageActions";
import OpacityTranslateAnimationContainer from "../components/translate-animation-container/OpacityTranslateAnimationContainer";
import LandingVideoBackground from "../components/LandingVideoBackground/LandingVideoBackground";
import images from "../utils/images";
import BarChart from "../components/bar-chart/BarChart";

let data = [
  {
    name: "African,American",
    male: 96,
    female: 3,
  },
  {
    name: "Native American/,Native Alaskan",
    male: 55,
    female: 5,
  },
  {
    name: "Latino/,Latina",
    male: 53,
    female: 2,
  },
  {
    name: "Total",
    male: 52,
    female: 3,
  },
  {
    name: "White",
    male: 35,
    female: 3,
  },
  {
    name: "Asian/Pacific,Islander",
    male: 18,
    female: 1,
  },
];
const landScapeUrl =
  "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fbackground-images%2Fvideo.mp4?alt=media&token=3d5d52dd-854b-4ff4-a5d1-346645aea544";
const portraitUrl =
  "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fbackground-images%2Fproduction%20ID_4884235.mp4?alt=media&token=b4803e47-1b32-48b7-b4f8-faeac86967dc";

const Home = ({}) => {
  document.title = "Home";
  const imageUrl =
    "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fbackground-images%2Fhome-landing-background.jpg?alt=media&token=36f36766-e504-4b20-868e-56b75b7a2c8c";
  const imageUrlMobile =
    "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fbackground-images%2Fhome-landing-background-mobile.jpg?alt=media&token=da18d267-605b-4589-8a96-3c651cb00648";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageLoading imageUrl={imageUrl} imageUrlMobile={imageUrlMobile}>
      <LandingBackground imageUrls={images} name={1} />
      <ProjectsContainer isHome />
      <OpacityTranslateAnimationContainer key={"landing-background"}>
        <LandingVideoBackground
          landScapeUrl={landScapeUrl}
          portraitUrl={portraitUrl}
          name={1}
        />
      </OpacityTranslateAnimationContainer>
      <PricingContainer />
    </PageLoading>
  );
};

const mapStateToProps = (state) => ({
  loading: state.pageData.loading,
  deviceWidth: state.deviceData.deviceWidth,
});
export default connect(mapStateToProps, { getPageLoading })(Home);
