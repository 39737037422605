import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormErrorMsg } from "../form/Form";
import YNButtons from "../form/YNButtons";
import { FlexContainer, ModalHeader } from "./modal.styles";
import ModalContainer from "./ModalContainer";
import { clearErrors } from "../../redux/actions/errorActions";

const ConfirmModal = ({
  handleCancel,
  errors,
  loading,
  successMessage,
  clearErrors,
  handleSubmit,
  title,
}) => {
  useEffect(() => {
    if (successMessage) {
      handleCancel();
    }
    return () => clearErrors();
  }, [successMessage]);

  return (
    <ModalContainer handleCancel={handleCancel}>
      <ModalHeader>{title}</ModalHeader>

      <form noValidate>
        <FormErrorMsg error={errors && errors.message} />
        <FlexContainer center noMedia>
          <YNButtons
            handleYes={handleSubmit}
            btnYesName="Confirm"
            btnNoName="Cancel"
            handleNo={handleCancel}
            loading={loading}
          />
        </FlexContainer>
      </form>
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  successMessage: state.successMessageData.successMessage,
});

export default connect(mapStateToProps, {
  clearErrors,
})(ConfirmModal);
