import React from "react";

import "./Footer.styles.scss";
const Footer = () => {
  return (
    <footer className="footer-nav-bar">
      <div>
        <h4>Developed by Min Khant Kyaw</h4>
      </div>
      <div className="footer-social-media-wrapper">
        <div className="social-media-logo-wrapper">
          <a
            href="https://github.com/minkkyaw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/data/images/icons/github-logo.png"
              alt="Github"
              className="github-logo"
            />
          </a>
        </div>
        <div className="social-media-logo-wrapper">
          <a
            href="https://www.linkedin.com/in/min-kyaw-918470174/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/data/images/icons/linkedin-logo.png"
              alt="Linkedin"
              className="social-media-logo"
            />
          </a>
        </div>
        <div className="social-media-logo-wrapper">
          <a
            href="https://www.facebook.com/minkhant.kyaw.100"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/data/images/icons/facebook-logo.png"
              alt="Facebook"
              className="social-media-logo"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
