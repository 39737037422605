import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import OpacityTranslateAnimationContainer from "../translate-animation-container/OpacityTranslateAnimationContainer";
import "./LandingVideo.styles.scss";

const LandingVideoBackground = ({ landScapeUrl, portraitUrl, deviceWidth }) => {
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    if (currentUrl === "") {
      if (deviceWidth > 800) {
        setCurrentUrl(landScapeUrl);
      } else if (deviceWidth > 0) {
        setCurrentUrl(portraitUrl);
      }
    }
  }, [deviceWidth]);
  return (
    <div className="landing-video-background">
      <div className="video-container">
        {currentUrl && deviceWidth && (
          <video
            key={deviceWidth}
            playsInline
            autoPlay
            muted
            loop
            preload="Video"
          >
            <source type="video/webm" src={currentUrl} />
          </video>
        )}
      </div>
      <div className="header">
        <OpacityTranslateAnimationContainer>
          <h1>Work With Us!</h1>
        </OpacityTranslateAnimationContainer>
        <OpacityTranslateAnimationContainer>
          <button>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/about-me"
            >
              Connect
            </Link>
          </button>
        </OpacityTranslateAnimationContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deviceWidth: state.deviceData.deviceWidth,
});

export default connect(mapStateToProps)(LandingVideoBackground);
