import React from 'react'

const PHByLocationTable = ({location, phByLocations}) => {
  return (
    <div style={{ width: 300, margin: "10px", padding: 10 }}>
      <div
        style={{
          position: "relative",
          backgroundColor: "#4D148C",
          color: "#fff",
          padding: 10,
        }}
      >
        <h3
          align="center"
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          {location}
        </h3>
        <div style={{ position: "absolute", right: 10, top: 12 }}>
          {phByLocations.length} PHs
        </div>
      </div>
      {phByLocations.length > 0 ? (
        phByLocations
          .map((ph) => (
            <div style={{ display: "flex" }}>
              <p
                style={{
                  width: 150,
                  lineHeight: 1,
                  margin: "10px 0",
                  padding: "0 20px",
                }}
              >
                {ph.id}
              </p>
              <p
                style={{
                  width: 150,
                  lineHeight: 1,
                  margin: "10px 0",
                  padding: "0 20px",
                }}
              >
                {ph.name}
              </p>
            </div>
          ))
      ) : (
        <p align="center">Not Assigned Yet!!!</p>
      )}
    </div>
  );
}

export default PHByLocationTable
