import { STOP_PAGELOADING, SET_PAGELOADING } from "../types";

export const getPageLoading = (imageUrl) => (dispatch) => {
  dispatch({ type: SET_PAGELOADING });
  const img = new Image();
  img.src = imageUrl;
  img.onload = function () {
    dispatch({ type: STOP_PAGELOADING });
  };
};
