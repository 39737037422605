import React, { useEffect, useState } from "react";

import "./ProjectsContainer.styles.scss";
import "../../pages/pages.styles.scss";
import ImageDescriptionContainer from "../image-description-container/ImageDescriptionContainer";

class Project {
  constructor(name, description, link, imageUrl) {
    this.name = name;
    this.description = description;
    this.link = link;
    this.imageUrl = imageUrl;
  }
}

const projects = [
  new Project(
    "Posana.org",
    "The organization was brought to fruition with the help of cherished community members, advisors, family and friends.",
    "https://posana.org/black-lives-matter",
    "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fproject-images%2Fposana.org.png?alt=media&token=7597e296-a846-4678-898f-8367fa08141c"
  ),
  new Project(
    "MK Finance",
    "Mk Finance website is a web app where you can manage your cash flow and expenses.",
    "https://mk-finance-ce7d8.web.app/",
    "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fproject-images%2FMKFinance.png?alt=media&token=563d1fab-b0b1-471d-89bf-760b8207ec9f"
  ),
  new Project(
    "AOP",
    "It includes employee management and trip records management in this web app.",
    "",
    "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fproject-images%2Faop-resized.png?alt=media&token=a3b84590-cfb5-462f-a451-b1c55d1a5b88"
  ),
  new Project(
    "Loshi",
    "Loshi website is a website where you can see their services.",
    "https://loshi-7e97e.web.app/",
    "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fproject-images%2Floshe.png?alt=media&token=7cf929b3-e669-47ea-9257-fbb8c63b43c3"
  ),

  new Project(
    "Spring Revolution",
    "This website shows videos and photos of military violence to the world and makes sure the fallen heroes, during Spring Revolution, unforgettable.",
    "https://springrevolutionmyanmar.com/",
    "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fproject-images%2FSpring%20Revolution%20Myanmar.png?alt=media&token=c3a0b3b3-cbf8-4ad6-bb96-d7f9aac36d9c"
  ),
  new Project(
    "Kay Online",
    "Kay website is a website where you can check out your cases.",
    "https://kaylin-online.web.app/",
    "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fproject-images%2Fkayonline.png?alt=media&token=89e2ee2b-f5b5-4732-9cdf-75cea0799b24"
  ),
  new Project(
    "Munchies",
    "Munchies website is a website where you can see the menu, make reservations and choose the table for reservation,and you can also order through website and checkout.",
    "https://munchies-251319.web.app/",
    "https://firebasestorage.googleapis.com/v0/b/mkwebdeveloper-01.appspot.com/o/website-images%2Fproject-images%2Fmunchies.png?alt=media&token=10f42fb4-0294-450b-a074-ae75eaf88b19"
  ),
];

const ProjectsContainer = ({ isHome }) => {
  const [displayProjects, setDisplayProjects] = useState(projects);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isHome) {
      const displayProjects = projects.filter((project, i) => i < 3);
      setDisplayProjects(displayProjects);
    }
    setLoading(false);
  }, [isHome]);

  return (
    <div className="projects-container">
      <h1 className="container-title">Projects</h1>

      {!loading &&
        displayProjects.map((project, i) => {
          if (isHome && i > 3) return <></>;
          if (i % 2 !== 0)
            return (
              <ImageDescriptionContainer key={i} project={project} reverse />
            );
          return <ImageDescriptionContainer key={i} project={project} />;
        })}
    </div>
  );
};

export default ProjectsContainer;
