import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import images from "../../utils/images";
import "./Slider.styles.scss";

const Slider = ({ deviceWidth }) => {
  const [allowShift, setAllowShift] = useState(true);
  const [dragStartClientX, setDragStartClientX] = useState();
  const [index, setIndex] = useState(1);
  const [sensitivity, setSensitivity] = useState(50);
  const slider = useRef();
  const sliderItems = useRef();
  const prev = useRef();
  const next = useRef();
  useEffect(() => {
    const interval = setInterval(() => {
      shiftRight();
    }, 5000);
    return () => clearInterval(interval);
  }, [deviceWidth, allowShift]);

  useEffect(() => {
    // slide(0,slider.current, sliderItems.current, prev.current, next.current, slides, deviceWidth,slides.length);
    sliderItems.current.style.transform = `translateX(-${
      index * deviceWidth
    }px)`;
    if (deviceWidth < 800) setSensitivity(10);
    else setSensitivity(50);
  }, [deviceWidth]);

  const shiftLeft = () => {
    if (allowShift) {
      setAllowShift(false);
      sliderItems.current.style.transition = `ease-out .5s transform`;
      sliderItems.current.style.transform = `translateX(-${
        (index - 1) * deviceWidth
      }px)`;
      setIndex(index - 1);
    }
  };

  const shiftRight = () => {
    if (allowShift) {
      setAllowShift(false);
      sliderItems.current.style.transition = `ease-out .5s transform`;
      sliderItems.current.style.transform = `translateX(-${
        (index + 1) * deviceWidth
      }px)`;
      setIndex(index + 1);
    }
  };

  const handleTranslateLeft = () => {
    shiftLeft();
  };
  const handleTranslateRight = () => {
    shiftRight();
  };
  const handleTransitionEnd = () => {
    sliderItems.current.style.transition = ``;
    if (index < 1) {
      setIndex(images.length - 2);
      sliderItems.current.style.transform = `translateX(-${
        (images.length - 2) * deviceWidth
      }px)`;
    }

    if (index === images.length - 1) {
      setIndex(1);
      sliderItems.current.style.transform = `translateX(-${deviceWidth}px)`;
    }

    setAllowShift(true);
  };

  const handleTouchStart = (e) => {
    setDragStartClientX(e.touches[0].screenX);
  };

  const handleTouchEnd = (e) => {
    const diff = dragStartClientX - e.changedTouches[0].screenX;

    if (diff > sensitivity) {
      shiftRight();
    } else if (diff < -sensitivity) {
      shiftLeft();
    }
  };
  const handleDragEnd = (e) => {
    const diff = dragStartClientX - e.clientX;
    if (diff > sensitivity) {
      shiftRight();
    } else if (diff < -sensitivity) {
      shiftLeft();
    }
  };

  const handleDragStart = (e) => {
    setDragStartClientX(e.clientX);
  };

  const handleBottomDotClick = (num) => {
    if (allowShift) {
      setAllowShift(false);
      sliderItems.current.style.transition = `ease-out .5s transform`;
      sliderItems.current.style.transform = `translateX(-${
        num * deviceWidth
      }px)`;
      setIndex(num);
    }
  };

  const onDragOver = (e) => {
    e.dataTransfer.dropEffect = "move";
    e.preventDefault();
  };
  const onDragEnter = (e) => {
    e.preventDefault();
  };

  return (
    <div ref={slider} className="slider">
      <div className="wrapper">
        <div
          draggable={false}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTransitionEnd={handleTransitionEnd}
          ref={sliderItems}
          className="slides"
          style={{
            width: `${images.length * deviceWidth}px`,
          }}
        >
          {images.map((image, i) => (
            <img
              draggable={true}
              style={{ width: deviceWidth }}
              key={image + i}
              src={image}
              alt={image}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          padding: "11px 20px",
        }}
        ref={prev}
        onClick={handleTranslateLeft}
        className="control prev"
      >
        <h2>
          <i className="fa fa-angle-left" aria-hidden="true"></i>
        </h2>
      </div>
      <div
        style={{
          padding: "11px 20px",
        }}
        ref={next}
        onClick={handleTranslateRight}
        className="control next"
      >
        <h2>
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h2>
      </div>
      <div className="bottom-dots">
        {images.map((image, i) => {
          if (i !== 0 && i !== images.length - 1) {
            return (
              <div
                key={i}
                className="bottom-dot"
                onClick={() => handleBottomDotClick(i)}
                style={{ backgroundColor: index === i ? "#999" : "#fff" }}
              ></div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  deviceWidth: state.deviceData.deviceWidth,
});
export default connect(mapStateToProps)(Slider);
