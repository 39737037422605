import React, { useState, useEffect } from "react";
import "./PageSelection.styles.scss";

import { withRouter } from "react-router-dom";
import { objToString, stringToObj } from "../../utils/utilsFunc";

const PageSelection = ({ pagesLoading, totalPages, history }) => {
  const [totalPagesArr, setTotalPagesArr] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    let queryObjFromHistory = stringToObj(history.location.search);
    if (queryObjFromHistory.page) setPage(queryObjFromHistory.page);
    let arr = [];
    for (let i = 0; i < totalPages; i++) {
      arr.push(i + 1);
    }
    setTotalPagesArr(arr);
  }, [totalPages, history.location.search]);

  const changePage = (option) => {
    let queryObjFromHistory = stringToObj(history.location.search);
    queryObjFromHistory.page = option;
    setPage(option);
    history.push({ search: objToString(queryObjFromHistory) });
  };
  return (
    <div className="page-selection-container">
      {!pagesLoading &&
        totalPagesArr.length > 0 &&
        totalPagesArr.map((pageNo, i) => (
          <div
            className="page-no"
            style={
              parseInt(page) === pageNo
                ? { backgroundColor: "#555", color: "#fff" }
                : {}
            }
            key={i}
            onClick={() => changePage(pageNo)}
          >
            {pageNo}
          </div>
        ))}
    </div>
  );
};

export default withRouter(PageSelection);
