export const getPosition = (element) => {
  var xPosition = 0;
  var yPosition = 0;

  while (element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
    yPosition += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }

  return { x: xPosition, y: yPosition };
};

export const formattedDate = (dateString, time) => {
  const d = new Date(dateString);
  const date = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const hh = d.getHours();
  const mm = d.getMinutes();
  const formattedDate = `${month}/${date}/${year}`;
  if (time) return formattedDate + ` ${hh}:${mm}`;
  return formattedDate;
};

export const stringToObj = (queryString) => {
  let obj = {};
  queryString
    .replace("?", "")
    .split("&")
    .filter((str) => str !== "")
    .map((str) => {
      const arr = str.split("=");
      obj[arr[0]] = arr[1];
    });
  return obj;
};

export const objToString = (obj) => {
  let queryString = "?";
  let keys = Object.keys(obj);
  keys.map((key, i) => {
    queryString += `${key}=${obj[key]}${i == keys.length - 1 ? "" : "&"}`;
  });
  return queryString;
};
