import React from "react";
import { Button, YNButtonsContainer } from "../form/Form.styles";
import LoadingCircle from "../loading-circle/LoadingCircle";

const YNButtons = ({ btnYesName, btnNoName, handleYes, handleNo, loading }) => {
  return (
    <YNButtonsContainer>
      <Button
        btn_danger
        width={100}
        margin={`0px 20px 0px 0px`}
        onClick={handleYes}
        style={{ backgroundColor: "#ff3b3b" }}
      >
        {loading ? (
          <LoadingCircle size={15} />
        ) : btnYesName ? (
          btnYesName
        ) : (
          "Yes"
        )}
      </Button>
      <Button
        style={{ backgroundColor: "#555" }}
        width={100}
        margin={`0px 20px 0px 0px`}
        onClick={handleNo}
      >
        {btnNoName ? btnNoName : "No"}
      </Button>
    </YNButtonsContainer>
  );
};

export default YNButtons;
