import React from "react";
import { connect } from "react-redux";
import "./LandingBackground.styles.scss";
import Slider from "../image-slider/Slider";

const LandingBackground = ({
  name,
  imageUrl,
  imageUrls,
  deviceWidth,
  absolute,
}) => {
  return (
    <div className="images-container">
      {deviceWidth > 0 && imageUrls && imageUrls.length > 0 ? (
        <Slider slides={imageUrls} name={name} />
      ) : (
        <div className="landing-background">
          <img src={imageUrl} alt="CRPH" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  deviceWidth: state.deviceData.deviceWidth,
});

export default connect(mapStateToProps)(LandingBackground);
