import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setSuccessMessage } from "../../redux/actions/successMessageActions";
import {
  updateRouteNoBySearchKey,
  updateUnlocatedAddress,
} from "../../redux/actions/unlocatedAddressActions";
import LoadingCircle from "../loading-circle/LoadingCircle";
import "./UnlocatedAddressRow.styles.scss";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Fragment } from "react";

const UnlocatedAddressRow = ({
  address,
  updateUnlocatedAddress,
  updateRouteNoBySearchKey,
  updateLoading,
  successMessage,
  setSuccessMessage,
  handleEdit,
  handleDelete,
  triggerWidth,
  deviceWidth,
}) => {
  const [routeNo, setRouteNo] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [edit, setEdit] = useState(false);
  const [editSearchKey, setEditSearchKey] = useState(false);

  useEffect(() => {
    setSearchKey(address.searchKey);
  }, []);

  useEffect(() => {
    if (!updateLoading && successMessage) {
      setEditSearchKey(false);
      setEdit(false);
    }
  }, [updateLoading, successMessage]);
  const handleRouteNoChange = (e) => {
    if (e.target.value.length < 5) setRouteNo(e.target.value);
  };

  const handleUpdateRouteNo = (e) => {
    if (e.key === "Enter") {
      updateRouteNoBySearchKey(address.searchKey, e.target.value);
    }
  };

  const handleSearchKeyChange = (e) => {
    setSearchKey(e.target.value);
  };

  const handleUpdateSearchKey = (e) => {
    if (e.key === "Enter") {
      updateUnlocatedAddress(address._id, { searchKey: searchKey });
    }
  };
  const handleCopySearchKey = () => {
    navigator.clipboard.writeText(address.searchKey);
    setSuccessMessage("Copied");
  };

  useEffect(() => {
    const routeNumberInput = document.querySelector(".route-no-input");
    const searchKeyInput = document.querySelector(".search-key-input");
    if (edit && routeNumberInput) routeNumberInput.focus();
    if (editSearchKey && searchKeyInput) searchKeyInput.focus();
  }, [edit, editSearchKey, updateLoading]);

  return (
    <tr className="unlocated-address-row">
      <td
        className="unlocated-address-td"
        style={{
          padding: 4,
          width: 300,
        }}
      >
        {address.streetName} {address.city ? address.city + " " : " "}
        {address.zipcode}
        {address.minStreetNumber
          ? ` (${address.minStreetNumber} - ${address.maxStreetNumber})`
          : ""}
      </td>
      <td
        className="unlocated-address-td"
        style={{
          width: deviceWidth > triggerWidth ? 100 : 70,
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setEdit(true);
        }}
      >
        {edit && updateLoading ? (
          <LoadingCircle size={15} />
        ) : deviceWidth > triggerWidth && edit && !updateLoading ? (
          <input
            className="route-no-input"
            type="text"
            value={routeNo}
            placeholder="Route No."
            onBlur={() => setEdit(false)}
            onChange={handleRouteNoChange}
            onKeyPress={handleUpdateRouteNo}
          />
        ) : (
          <span key={address.searchKey}>{address.routeNo}</span>
        )}
      </td>
      {deviceWidth > triggerWidth && (
        <Fragment>
          <td
            id="searchKey"
            className="unlocated-address-td"
            style={{
              textAlign: "center",
              cursor: "pointer",
              padding: 0,
            }}
            onDoubleClick={() => setEditSearchKey(true)}
          >
            {editSearchKey && updateLoading ? (
              <LoadingCircle size={15} />
            ) : editSearchKey && !updateLoading ? (
              <input
                className="search-key-input"
                style={{ width: 200 }}
                type="text"
                value={searchKey}
                placeholder="Enter Search Key"
                onBlur={() => setEditSearchKey(false)}
                onChange={handleSearchKeyChange}
                onKeyPress={handleUpdateSearchKey}
              />
            ) : (
              <span onClick={handleCopySearchKey} key={address.searchKey}>
                {address.searchKey}
              </span>
            )}
          </td>
          <td style={{ backgroundColor: "#fff", width: 100 }}>
            <EditIcon
              onClick={(e) => handleEdit(address)}
              className="unlocated-row-icon"
            />
            <DeleteIcon
              onClick={(e) => handleDelete(address)}
              className="unlocated-row-icon"
            />
          </td>
        </Fragment>
      )}
    </tr>
  );
};

const mapStateToProps = (state) => ({
  updateLoading: state.unlocatedAddressData.updateLoading,
  successMessage: state.successMessageData.successMessage,
  deviceWidth: state.deviceData.deviceWidth,
});

export default connect(mapStateToProps, {
  updateUnlocatedAddress,
  updateRouteNoBySearchKey,
  setSuccessMessage,
})(UnlocatedAddressRow);
