import React from "react";
import "./Emails.styles.scss";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Filter from "../filter/Filter";
import PageSelection from "../page-selection/PageSelection";
import LoadingCircle from "../loading-circle/LoadingCircle";

const Emails = () => {
  const [emails, setEmails] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [emailsloading, setEmailsLoading] = useState(true);
  const [pageNoloading, setPageNoLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const changeFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };
  useEffect(() => {
    setPageNoLoading(true);
    axios
      .get(
        `/customer-mails/page-no?${
          filter && `reason=${filter}`
        }&page=${page}&limit=10`
      )
      .then((res) => {
        setTotalPages(res.data.data.pageNo);
        setPageNoLoading(false);
      })
      .catch((err) => console.log(err));
  }, [filter]);

  useEffect(() => {
    setEmailsLoading(true);
    setTimeout(() => {
      axios
        .get(
          `/customer-mails?${
            filter && `reason=${filter}`
          }&page=${page}&limit=10`
        )
        .then((res) => {
          setEmails(res.data.data.data);
          setEmailsLoading(false);
        })
        .catch((err) => console.log(err));
    }, 1000);
  }, [page, filter]);
  return (
    <div className="mail-container">
      <Filter changeFilter={changeFilter} filter={filter} />

      {pageNoloading ? (
        <div className="mail-table-loading-container">
          <LoadingCircle size={120} />
        </div>
      ) : (
        <div className="mail-table-container">
          <PageSelection
            totalPages={totalPages}
            page={page}
            setPage={setPage}
          />
          {!emailsloading ? (
            <table className="mail-table">
              <tbody>
                {emails.map((email, i) => (
                  <tr
                    key={i}
                    className={`mail-container ${
                      !email.visited && "not-visited"
                    }`}
                  >
                    <td className="mail-details">
                      <p className="name">{email.name}</p>
                    </td>
                    <td className="mail-details">
                      <p className="reason">
                        {email.reason
                          .replace(/([A-Z])/g, " $1")
                          // uppercase the first character
                          .replace(/^./, function (str) {
                            return str.toUpperCase();
                          })}
                      </p>
                    </td>
                    <td className="mail-details">
                      <p className="description">
                        {email.description.slice(0, 30)}...
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="mail-table">
              <LoadingCircle size={120} />
            </div>
          )}
          <PageSelection
            totalPages={totalPages}
            page={page}
            setPage={setPage}
          />
        </div>
      )}
    </div>
  );
};

export default Emails;
