import { SET_SUCCESSMESSAGE, CLEAR_SUCCESSMESSAGE } from "../types";

const initialState = {
  successMessage: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUCCESSMESSAGE:
      return { ...state, successMessage: action.payload };
    case CLEAR_SUCCESSMESSAGE: {
      return { ...state, successMessage: "" };
    }
    default:
      return state;
  }
};
