import React, { Component } from "react";

import "./MapContainer.styles.scss";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const LoadingContainer = () => <div></div>;

class MapContainer extends Component {
  location = { lat: 39.929297, lng: -75.067467 };
  name = "MK Web Developer";
  render() {
    return (
      <div className="map-canvas-container">
        <Map
          google={this.props.google}
          className="map my-location-map-container"
          initialCenter={this.location}
          zoom={16}
        >
          <Marker
            name={this.name}
            position={this.location}
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${this.location.lat},${this.location.lng}`,
                "_blank"
              )
            }
          ></Marker>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAOdxjSxk9xokiMzHUOmLtkv_g0Yc3RjJU",
  LoadingContainer,
})(MapContainer);
