import React from "react";
import "./pages.styles.scss";

const AOPManger = () => {
  return (
    <div className="page-container">
      <h1>This is manager page</h1>
    </div>
  );
};

export default AOPManger;
