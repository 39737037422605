import styled from "styled-components";
export const UploadImagesConainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ModalContainerDiv = styled.div`
  z-index: 100;
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: fit-content;
  background-color: rgba(73, 73, 73, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ModalInnerContainerDiv = styled.div`
  width: 400px;
  background-color: #fff;
  text-align: center;
  ${({ padding }) => `padding: ${padding};`}
  padding: 0 0 20px;
  border-radius: 5px;
  overflow: hidden;

  ${({ responsiveHeight }) =>
    responsiveHeight
      ? `
      @media (max-height: ${responsiveHeight}px) { 
        height: 100vh; 
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }`
      : ``}

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const ModalHeader = styled.h3`
  padding: 20px;
  background-color: #ff3b3b;
  color: #fff;
  margin: 0;
`;

export const FlexContainer = styled.div`
  display: flex;
  padding: 20px;
  margin: 0 auto;
  height: fit-content;
  ${({ center }) => `
    ${center ? `align-items: center; justify-content: center;` : ""}
  `}
  ${({ IsMedia, noMedia }) => {
    const a = IsMedia
      ? `@media(max-width: 992px) { flex-direction: column; padding: 0;}`
      : noMedia
      ? ""
      : `@media(max-width: 600px) {
    flex-direction: column;
    padding: 0;}`;
    return `${a}`;
  }}
`;
