import React from "react";

import "./ImageDescriptionContainer.styles.scss";
import AnimationContainer from "../translate-animation-container/AnimationContainer";
import { connect } from "react-redux";

const ImageDescriptionContainer = ({ reverse, project, deviceWidth }) => {
  const { name, description, link, imageUrl } = project;
  const descriptionBeforeAnimationStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 1.5,
    transform: "translateX(-100px)",
    opacity: 0,
    transition: "all 2s ease",
  };
  const descriptionBeforeAnimationStyleReverse = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 1.5,
    transform: "translateX(100px)",
    opacity: 0,
    transition: "all 2s ease",
  };

  const descriptionAnimationStyle = {
    transform: "translateX(0)",
    opacity: 1,
  };

  const imageBeforeAnimationStyle = {
    transform: "translateX(-50px)",
    opacity: 0,
    transition: "all 1s ease",
  };
  const imageBeforeAnimationStyleReverse = {
    transform: "translateX(+50px)",
    opacity: 0,
    transition: "all 1s ease",
  };

  const imageAnimationStyle = { transform: "translateX(0)", opacity: 1 };

  return (
    <div
      className={`image-description-container`}
      style={
        window.innerWidth > 480 && reverse
          ? { flexDirection: "row-reverse" }
          : {}
      }
    >
      <AnimationContainer
        beforeAnimationStyle={
          reverse ? imageBeforeAnimationStyleReverse : imageBeforeAnimationStyle
        }
        animationStyle={imageAnimationStyle}
        adjustedScrollHeight={deviceWidth > 800 ? 250 : 0}
      >
        <div
          className="image-container"
          style={{ backgroundImage: `url('${imageUrl}')` }}
        ></div>
      </AnimationContainer>
      <div className="description-container">
        <AnimationContainer
          beforeAnimationStyle={
            reverse
              ? descriptionBeforeAnimationStyle
              : descriptionBeforeAnimationStyleReverse
          }
          animationStyle={descriptionAnimationStyle}
          adjustedScrollHeight={deviceWidth > 800 ? 250 : 0}
        >
          <div className="description">
            <h2 className="title">{name}</h2>
            <p>{description}</p>
            {link && (
              <a
                className="project-link"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Website
              </a>
            )}
          </div>
        </AnimationContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deviceWidth: state.deviceData.deviceWidth,
});

export default connect(mapStateToProps)(ImageDescriptionContainer);
