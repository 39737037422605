import React from "react";

import "./AnimatedDot.styles.scss";

const AnimatedDot = ({ inline }) => {
  return (
    <div style={{ width: "fit-content", display: "flex", margin: "50px auto" }}>
      <div className="spinner" style={{ width: 100 }}>
        <div
          style={{ width: 20, height: 20, margin: "0 5px" }}
          className="bounce1"
        ></div>
        <div
          style={{ width: 20, height: 20, margin: "0 5px" }}
          className="bounce2"
        ></div>
        <div
          style={{ width: 20, height: 20, margin: "0 5px" }}
          className="bounce3"
        ></div>
      </div>
    </div>
  );
};

export default AnimatedDot;
