export const SET_PAGELOADING = "SET_PAGELOADING";
export const STOP_PAGELOADING = "STOP_PAGELOADING";

export const SET_DEVICESIZE = "SET_DEVICESIZE";
export const SET_DEVICECOUNTRY = "SET_DEVICECOUNTRY";

export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_UNLOCATEDADDRESSLOADING = "SET_UNLOCATEDADDRESSLOADING";
export const STOP_UNLOCATEDADDRESSLOADING = "STOP_UNLOCATEDADDRESSLOADING";
export const SET_UNLOCATEDADDRESSUPDATELOADING =
  "SET_UNLOCATEDADDRESSUPDATELOADING";
export const STOP_UNLOCATEDADDRESSUPDATELOADING =
  "STOP_UNLOCATEDADDRESSUPDATELOADING";
export const SET_UNLOCATEDADDRESSES = "SET_UNLOCATEDADDRESSES";
export const SET_UNLOCATEDADDRESS = "SET_UNLOCATEDADDRESS";
export const ADD_UNLOCATEDADDRESS = "ADD_UNLOCATEDADDRESS";
export const DELETE_UNLOCATEDADDRESS = "DELETE_UNLOCATEDADDRESS";
export const UPDATE_UNLOCATEDADDRESS = "UPDATE_UNLOCATEDADDRESS";
export const UPDATE_UNLOCATEDADDRESSROUTENO = "UPDATE_UNLOCATEDADDRESSROUTENO";
export const CLEAR_CURRENTUNLOCATEDADDRESS = "CLEAR_CURRENTUNLOCATEDADDRESS";
export const STOP_UNLOCATEDADDRESSPAGESLOADING =
  "STOP_UNLOCATEDADDRESSPAGESLOADING";
export const SET_UNLOCATEDADDRESSPAGESLOADING =
  "SET_UNLOCATEDADDRESSPAGESLOADING";
export const STOP_UPDATEUNLOCATEDADDRESSPAGESLOADING =
  "STOP_UPDATEUNLOCATEDADDRESSPAGESLOADING";
export const SET_UNLOCATEDADDRESSPAGES = "SET_UNLOCATEDADDRESSPAGES";
export const SET_UPDATEUNLOCATEDADDRESSESLOADING =
  "SET_UPDATEUNLOCATEDADDRESSESLOADING";
export const STOP_UPDATEUNLOCATEDADDRESSESLOADING =
  "STOP_UPDATEUNLOCATEDADDRESSESLOADING";

export const SET_SUCCESSMESSAGE = "SET_SUCCESSMESSAGE";
export const CLEAR_SUCCESSMESSAGE = "CLEAR_SUCCESSMESSAGE";

export const STREETNAME = "Street Name";
export const SEARCHKEY = "Search Key";
