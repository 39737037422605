import { SET_PAGELOADING, STOP_PAGELOADING } from "../types";

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGELOADING:
      return { ...state, loading: true };
    case STOP_PAGELOADING:
      return { ...state, loading: false };

    default:
      return state;
  }
};
