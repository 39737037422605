import React from "react";
import { ModalContainerDiv, ModalInnerContainerDiv } from "./modal.styles";
const ModalContainer = ({ children, responsiveHeight }) => {
  return (
    <ModalContainerDiv className="modal">
      <ModalInnerContainerDiv responsiveHeight={responsiveHeight}>
        {children}
      </ModalInnerContainerDiv>
    </ModalContainerDiv>
  );
};

export default ModalContainer;
