import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormBoxInput, FormErrorMsg, FormSelect } from "../../form/Form";
import YNButtons from "../../form/YNButtons";
import { FlexContainer, ModalHeader } from "../modal.styles";
import {
  addUnlocatedAddress,
  updateUnlocatedAddress,
} from "../../../redux/actions/unlocatedAddressActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import ModalContainer from "../ModalContainer";
import { setSuccessMessage } from "../../../redux/actions/successMessageActions";

const AddUnlocatedAddressModal = ({
  contractorNames,
  handleCancel,
  errors,
  addUnlocatedAddress,
  successMessage,
  clearErrors,
  uploadImage,
  updateLoading,
  edit,
  unlocatedAddress,
  updateUnlocatedAddress,
}) => {
  const [maxStreetNumber, setMaxStreetNumber] = useState(null);
  const [minStreetNumber, setMinStreetNumber] = useState(null);
  const [contractorName, setContractorName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    if (edit) {
      setMaxStreetNumber(unlocatedAddress.maxStreetNumber);
      setMinStreetNumber(unlocatedAddress.minStreetNumber);
      setContractorName(unlocatedAddress.contractorName);
      setStreetName(unlocatedAddress.streetName);
      setCity(unlocatedAddress.city);
      setZipcode(unlocatedAddress.zipcode);
      setSearchKey(unlocatedAddress.searchKey);
    }
  }, []);
  useEffect(() => {
    if (successMessage) {
      handleCancel();
    }
  }, [successMessage]);

  const handleInputChange = (event) => {
    clearErrors();

    switch (event.target.name) {
      case "maxStreetNumber":
        return setMaxStreetNumber(event.target.value);
      case "minStreetNumber":
        return setMinStreetNumber(event.target.value);
      case "contractorName":
        return setContractorName(event.target.value);
      case "streetName":
        return setStreetName(event.target.value);
      case "city":
        return setCity(event.target.value);
      case "zipcode":
        return setZipcode(event.target.value);
      case "searchKey":
        return setSearchKey(event.target.value);
      default:
        return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearErrors();

    const newItemData = {
      maxStreetNumber,
      minStreetNumber,
      contractorName,
      streetName: streetName.toLowerCase(),
      city,
      zipcode,
      searchKey,
    };
    if (edit) {
      updateUnlocatedAddress(unlocatedAddress._id, newItemData);
    } else addUnlocatedAddress(newItemData);
  };

  return (
    <ModalContainer
      responsiveHeight={uploadImage ? 330 : 937}
      handleCancel={() => {}}
    >
      <ModalHeader>Add Unlocated Address</ModalHeader>
      <form style={{ padding: "0 15px" }} noValidate>
        <FormErrorMsg
          error={errors && errors.message ? errors.message.error : ""}
          formError={true}
        />
        <FormSelect
          selectedOption={contractorName}
          optionTitle="Select contractor name"
          options={contractorNames}
          label="Contractor Name"
          width="100%"
          onChange={handleInputChange}
          name="contractorName"
          type="text"
          value={contractorName}
          error={errors && errors.message ? errors.message.contractorName : ""}
          placeholder="Contractor Name"
          required
        />
        <FormBoxInput
          label="Min. Street Number(Optional)"
          width="100%"
          onChange={handleInputChange}
          name="minStreetNumber"
          type="number"
          value={minStreetNumber}
          error={errors && errors.message ? errors.message.minStreetNumber : ""}
          placeholder="Min. Street Number"
          required
        />
        <FormBoxInput
          label="Max. Street Number(Optional)"
          width="100%"
          onChange={handleInputChange}
          name="maxStreetNumber"
          type="number"
          value={maxStreetNumber}
          error={errors && errors.message ? errors.message.maxStreetNumber : ""}
          placeholder="Max. Street Number"
          required
        />
        <FormBoxInput
          label="Street Name"
          width="100%"
          onChange={handleInputChange}
          name="streetName"
          type="text"
          value={streetName}
          error={errors && errors.message ? errors.message.streetName : ""}
          placeholder="Enter street name"
          required
        />
        <FormBoxInput
          label="City"
          width="100%"
          onChange={handleInputChange}
          name="city"
          type="text"
          value={city}
          error={errors && errors.message ? errors.message.city : ""}
          placeholder="Enter city"
          required
        />
        <FormBoxInput
          label="Zipcode"
          width="100%"
          onChange={handleInputChange}
          name="zipcode"
          type="text"
          value={zipcode}
          error={errors && errors.message ? errors.message.zipcode : ""}
          placeholder="Enter zipcode"
          required
        />

        <FormBoxInput
          label="Search Key"
          width="100%"
          onChange={handleInputChange}
          name="searchKey"
          type="text"
          value={searchKey}
          error={errors && errors.message ? errors.message.searchKey : ""}
          placeholder="Enter search key"
          required
        />

        <FlexContainer center noMedia>
          <YNButtons
            handleYes={handleSubmit}
            btnYesName={edit ? "Edit" : "Add"}
            btnNoName="Cancel"
            handleNo={handleCancel}
            loading={updateLoading}
          />
        </FlexContainer>
      </form>
    </ModalContainer>
  );
};

AddUnlocatedAddressModal.propTypes = {};

const mapStateToProps = (state) => ({
  updateLoading: state.unlocatedAddressData.updateLoading,
  updateDetainedLoading: state.unlocatedAddressData.updateDetainedLoading,
  successMessage: state.successMessageData.successMessage,
  errors: state.errorData.errors,
});

export default connect(mapStateToProps, {
  updateUnlocatedAddress,
  setSuccessMessage,
  clearErrors,
  addUnlocatedAddress,
})(AddUnlocatedAddressModal);
