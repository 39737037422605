import styled, { css } from "styled-components";

const inputCSS = css`
  -webkit-appearance: none;
  display: inline-block;
  font-family: inherit;
  outline: 0;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 10px;
  transition: box-shadow 1000ms ease;
  border: none;
  -webkit-appearance: none;
  box-shadow: 0 0 0px 1px #ddd;
  -webkit-box-shadow: 0 0 0px 1px #ddd;
  :hover {
    box-shadow: 0 0 0 2px #218f76;
    -web-box-shadow: 0 0 0 2px #218f76;
  }

  :focus {
    box-shadow: 0 0 0 2px #218f76;
    -web-box-shadow: 0 0 0 2px #218f76;
  }
  :visited {
    background-color: none;
  }
`;

export const Input = styled.input`
  ${inputCSS}
  width: 100%;
`;

export const InputErrorMsg = styled.div`
  color: #d63031;
  text-align: center;
  height: 25px;
  width: 100%;
`;

export const InputSuccessMsg = styled.div`
  color: #fff;
  text-align: center;
  height: 0;
  width: 200px;
  position: fixed;
  top: 0;
  left: 50%;
  background-color: #10a881;
  transform: translateX(-100px);
  z-index: 100;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  transition: height 1s ease;
  }
`;

export const FormInputContainer = styled.div`
  margin: 0 auto 10px;
  width: 100%;
  padding: 2px 0;
  @media (max-width: 600px) {
    width: calc(100% - 20px);
    padding: 0 10px;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: inherit;
  outline: 0;
  margin: 0 auto;
  min-width: 100px;
  ${({ width }) => (width ? `width: ${width};` : "width: 100px;")}
  padding: 10px 20px;
  background-color: #4d148c;
  color: #fff;
  border: none;
  border-radius: 10px;
  transition: background-color 0.5s ease;
  :hover {
    background-color: #8942d6;
  }
`;

export const TextArea = styled.textarea`
  ${({ placeholder }) => {
    if (placeholder) return `color: #777;`;
  }}
  resize: none;
  ${inputCSS}
  width: 100%;
`;

export const SearchInput = styled.input`
  ${inputCSS}
  border-radius: 20px;
  border: 1px solid #bbb;
  margin-right: 20px;
  width: 100%;
`;

export const YNButtonsContainer = styled.div`
  display: flex;
  justify-content: "space-between";
  margin: 0 auto;
  width: 300px;
`;
