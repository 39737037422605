import { SET_DEVICESIZE, SET_DEVICECOUNTRY } from "../types";

const initialState = {
  deviceWidth: 0,
  deviceHeight: 0,
  deviceCountry: "US",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DEVICESIZE:
      return {
        ...state,
        deviceWidth: window.innerWidth,
        deviceHeight: window.innerHeight,
      };
    case SET_DEVICECOUNTRY:
      return {
        ...state,
        deviceCountry: action.payload,
      };
    default:
      return state;
  }
};
